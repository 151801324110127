
import {Options, Vue} from 'vue-class-component';
import ProxyCommon from '@/web/operation/proxy/common/index.vue';

@Options({
  components: {
    ProxyCommon
  }
})
export default class ProxyRegister extends Vue {
  title = 'ui_text_049';
  settings = {
    active_day: {
      name: 'ui_text_031',
      unitTxt: 'ui_day',
      items: [1, 5, 7, ''],
      itemHilight: 0,
      customItem: [3]
    },
    threshold: {
      name: 'ui_text_032',
      items: [100, 1000, 5000, 10000],
      itemHilight: 0,
      key: 'threshold',
      customItem: []
    },
    price: {
      name: 'ui_red_envelope_amount',
      unitTxt: 'ui_dollar',
      items: [1, 10, 100, ''],
      itemHilight: 0,
      key: 'price',
      customItem: [3]
    },
    num: {
      name: 'ui_number_of_red_packets',
      unitTxt: 'ui_each',
      items: [10, 30, 50, ''],
      itemHilight: 0,
      key: 'num',
      customItem: [3]
    }
  };
  info = {
    type: 1,
    active_day: 1,
    price: 0,
    num: 0,
    threshold: 0
  };
}
